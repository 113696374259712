<template>
  <div>
    <div class="cash-register-credit">
      <label>Provide credit</label>
      <b-form-input type="number" :min="0" v-model="credit" />
      <b-button @click="postCredit('CREDIT')" :disabled="credit <= 0" variant="success">
          Provide credit
      </b-button>
    </div>
    <div class="cash-register-credit">
      <label>Withdraw</label>
      <b-form-input type="number" v-model="withdraw" />
      <b-button @click="postCredit('WITHDRAW')" variant="danger">
          Withdraw
      </b-button>
    </div>
  </div>
</template>

<script>
import Toast from '@/components/Toast.vue'

export default {
  components: {},
  props: {
    courier: { type: String, required: true },
  },
  data() {
    return {
      credit: 0,
      withdraw: null,
      selectedCourier: this.courier,
    }
  },
  watch: {
    courier: {
      handler(iri) {
        this.selectedCourier = iri
      },
      immediate: true,
    },
  },
  methods: {
    postCredit(type) {
      const body = {
        amount: this.credit.toString(),
        cashRegistryType: type,
        courier: this.selectedCourier,
      }
      let message = "Credit has been provided"
      if(type === "WITHDRAW") {
        message = "Withdrawal has been made"
        body.amount = null
        if (this.withdraw) {
            body.amount = this.withdraw.toString()
        }
      }
      this.$CashRegistries
        .createResource({ body })
        .then(response => {
          if (response.status === 201) {
            this.$helper.showToast(
              this.$toast,
              Toast,
              message,
              '',
              'success',
            )
            this.credit = 0
            this.$emit('addedCredit')
          }
        })
        .catch(error => {
          if (error.response) {
            this.$helper.showToast(
              this.$toast,
              Toast,
              error.response.data['hydra:title'],
              error.response.data['hydra:description'],
              'danger',
            )
          }
        })
    },
  },
}
</script>
