<template>
  <div class="cash-register-listing">
    <div class="cash-register-listing-title">Payments history</div>
    <div
      v-for="(record, index) of records"
      :key="index"
      class="cash-register-listing-line"
    >
      <div class="cash-register-listing-id">
        <span v-if="record.cashRegistryType === 'CREDIT'" class="credit">
          {{ $helper.getEnumTranslation('cash_registry_type', 'CREDIT') }}
        </span>
        <span
          v-else-if="record.cashRegistryType === 'WITHDRAW'"
          class="withdraw"
        >
          {{ $helper.getEnumTranslation('cash_registry_type', 'WITHDRAW') }}
        </span>
        <span v-else>#{{ getOrderNumber(record.order) }}</span>
      </div>
      <div class="cash-register-listing-date">
        {{ record.createdAt | moment('DD.MM.YYYY HH:mm') }}
      </div>
      <div v-if="record.amount" class="cash-register-listing-amount">
        {{ parseFloat(record.amount) | price }}
      </div>
      <div v-else class="cash-register-listing-amount">
          Total withdrawal
      </div>
    </div>

    <div class="cash-register-listing-line-total">
      Total<strong>{{ parseFloat(cash) | price }}</strong>
    </div>

    <b-pagination
      v-if="pages / perPage > 1"
      class="custom-table-pagination"
      v-model="currentPage"
      :total-rows="pages"
      :per-page="perPage"
      @input="paginationChange"
    ></b-pagination>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    courier: { type: String, required: true },
    tick: { required: true },
  },
  data() {
    return {
      records: null,
      selectedCourier: this.courier,
      currentPage: 1,
      pages: null,
      perPage: 10,
      cash: 0,
    }
  },
  watch: {
    courier: {
      handler(iri) {
        this.selectedCourier = iri
      },
      immediate: true,
    },
    tick: {
      handler(tck) {
        if(tck) {
          this.loadCashRegister()
          this.calcCash()
        }
      },
      immediate: true,
    },
  },
  methods: {
    loadCashRegister() {
      if (this.selectedCourier) {
        const params = {
          courier: this.selectedCourier,
          itemsPerPage: this.perPage,
          page: this.currentPage,
          'order[createdAt]': 'desc',
        }

        this.$CashRegistries
          .getCollection({ params }, 'cash_registry_list')
          .then(response => {
            this.records = response.data['hydra:member']
            this.pages = response.data['hydra:totalItems']
          })
      }
    },
    calcCash() {
      this.cash = 0
      const uID = this.selectedCourier.split('/')[this.selectedCourier.split('/').length - 1]
      // eslint-disable-next-line prefer-template
      const url = '/cash_registries/courier/' + uID + '/balance'
      this.$CashRegistries.getResourceByUrl({ url }).then(response => {
        if (response.data) {
          this.cash = response.data.balance
        }
      })
    },
    paginationChange() {
      this.$router.push({
        query: { ...this.$route.query, cpage: this.currentPage },
      })
      this.loadCashRegister()
    },
    getOrderNumber(iri) {
      const arr = iri.split('/')
      return arr[arr.length - 1]
    },
  },
}
</script>
