<template>
  <div class="transaction-listing">
    <div class="transaction-listing-title">Transaction history</div>

    <div class="transaction-listing-filter">
      <v-select
        class="custom-select mr-2"
        v-model="payment"
        :options="paymentMethodList"
        :clearable="false"
        :searchable="false"
      >
        <template #open-indicator="{ attributes }">
          <span v-bind="attributes">
            <img src="@/assets/images/icons/filter-sort-down.svg" />
          </span>
        </template>
      </v-select>


      <flat-pickr
        v-model="date"
        :config="{
          mode: 'range',
          altFormat: 'd.m.Y H:i',
          dateFormat: 'Z',
          altInput: true,
          altInputClass: 'flatpickr-input',
          time_24hr: true,
          enableTime: true,
          locale: {
            rangeSeparator: ' - ',
          },
        }"
      ></flat-pickr>
    </div>

    <div
      v-for="(order, index) of orders"
      :key="index"
      class="transaction-listing-line"
    >
      <div class="transaction-listing-id">
        <span class="credit"> #{{ order.id }} </span>
      </div>
      <div class="transaction-listing-date">
        {{ order.closedAt | moment('DD.MM.YYYY HH:ss') }}
      </div>
      <div class="transaction-listing-payment">
        {{
          $helper.getEnumTranslation(
            'order_payment_method',
            order.paymentMethod,
          )
        }}
      </div>
      <div class="transaction-listing-amount">
        {{ parseFloat(order.totalPrice) | price }}
      </div>
    </div>

    <div class="transaction-listing-line-total">
      Total<strong>{{ parseFloat(tansactionTotal) | price }}</strong>
    </div>

    <b-pagination
      v-if="pages / perPage > 1"
      class="custom-table-pagination"
      v-model="currentPage"
      :total-rows="pages"
      :per-page="perPage"
      @input="paginationChange"
    ></b-pagination>
  </div>
</template>

<script>
import moment from 'moment'
import flatPickr from 'vue-flatpickr-component'
import ListUtils from '@/mixins/ListUtils'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'flatpickr/dist/flatpickr.css'

export default {
  components: {
    flatPickr,
  },
  props: {
    courier: { type: String, required: true },
    tick: { required: true },
  },
  mixins: [ListUtils],
  data() {
    return {
      selectedCourier: this.courier,
      orders: null,
      date: null,
      tansactionTotal: 0,
      paymentMethodList: [{ label: 'All payments', id: null }],
      payment: { label: 'All payments', id: null },
      currentPage: 1,
      pages: null,
      perPage: 10,
    }
  },
  created() {
    this.getEnumList('order_payment_method', this.paymentMethodList)

    // prepare date
    const m = moment()
    m.subtract(1, 'days')
    m.set({ hour: 18, minute: 0, second: 0, millisecond: 0 })
    const start = m.format()
    m.add(1, 'days')
    m.set({ hour: 5, minute: 30, second: 0, millisecond: 0 })
    const end = m.format()
    this.date = `${start} - ${end}`
  },
  watch: {
    courier: {
      handler(iri) {
        this.selectedCourier = iri
      },
      immediate: true,
    },
    date(newVal, oldVal) {
      if (newVal && newVal.split(' - ').length === 2 && oldVal !== null) {
        this.loadOrders()
        this.calcTotal()
      }
    },
    payment() {
      this.loadOrders()
      this.calcTotal()
    },
    tick: {
      handler(tck) {
        if (tck) {
          this.loadOrders()
          this.calcTotal()
        }
      },
      immediate: true,
    },
  },
  methods: {
    calcTotal() {
      this.tansactionTotal = 0

      const startDate = moment(this.date.split(' - ')[0])
      // startDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      const start = startDate.format()

      const endDate = moment(this.date.split(' - ')[1])
      // endDate.set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
      const end = endDate.format()

      const params = {
        pagination: false,
        courier: this.selectedCourier,
        status: 'CLOSED',
        'closedAt[after]': start,
        'closedAt[before]': end,
        'order[closedAt]': 'desc',
      }

      if (this.payment.id) {
        params.paymentMethod = this.payment.id
      }

      this.$Orders.getCollection({ params }, 'order_transaction_list').then(response => {
        response.data['hydra:member'].forEach(order => {
          this.tansactionTotal += parseFloat(order.totalPrice)
        })
      })
    },
    loadOrders() {
      const startDate = moment(this.date.split(' - ')[0])
      // startDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      const start = startDate.format()

      const endDate = moment(this.date.split(' - ')[1])
      // endDate.set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
      const end = endDate.format()

      const params = {
        courier: this.selectedCourier,
        itemsPerPage: this.perPage,
        page: this.currentPage,
        status: 'CLOSED',
        'closedAt[after]': start,
        'closedAt[before]': end,
        'order[closedAt]': 'desc',
      }

      if (this.payment.id) {
        params.paymentMethod = this.payment.id
      }

      this.$Orders.getCollection({ params }, 'order_transaction_detail_list').then(response => {
        this.orders = response.data['hydra:member']
        this.pages = response.data['hydra:totalItems']
      })
    },
    paginationChange() {
      this.$router.push({
        query: { ...this.$route.query, tpage: this.currentPage },
      })
      this.loadOrders()
    },
  },
}
</script>
