<template>
  <div class="admin-cash-register">
    <title-bar title="Payments" />

    <b-tabs
      pills
      class="order-listing-tabs"
      v-model="tabIndex"
      @input="courierChange"
    >
      <b-tab
        v-for="(courier, index) of couriers"
        :key="index"
        :title="courier.name"
      ></b-tab>
    </b-tabs>

    <div class="row">
      <div class="col-lg-6">
        <cash-register v-if="selectedCourier" :courier="selectedCourier" :tick="tick" />
        <add-credit v-if="selectedCourier" :courier="selectedCourier" @addedCredit="addedCredit" />
      </div>

      <div class="col-lg-6">
        <transactions v-if="selectedCourier" :courier="selectedCourier" :tick="tick" />
      </div>
    </div>
  </div>
</template>

<script>

import { BTabs } from 'bootstrap-vue'
import TitleBar from '@/components/TitleBar.vue'
import AddCredit from './components/AddCredit.vue'
import CashRegister from './components/listing.vue'
import Transactions from './components/Transactions.vue'

export default {
  name: 'AdminCashRegister',
  components: {
    TitleBar,
    BTabs,
    Transactions,
    AddCredit,
    CashRegister
  },
  data() {
    return {
      tick: 0,
      tabIndex: 0,
      couriers: [],
      selectedCourier: null,
    }
  },
  created() {
    this.$Users
      .getCollection({
        params: {
          pagination: false,
          role: 'ROLE_COURIER',
          'order[familyName]': 'asc',
          'enabled': true
        },
      }, 'user_list')
      .then(response => {
        response.data['hydra:member'].forEach(courier => {
          this.couriers.push({
            name: `${courier.givenName[0]}. ${courier.familyName}`,
            id: courier['@id'],
          })
        })
        this.selectedCourier = this.couriers[0].id
      })
  },
  methods: {
    addedCredit() {
      this.tick += 1
    },
    courierChange() {
      if (this.tabIndex !== -1) {
        this.selectedCourier = this.couriers[this.tabIndex].id
        this.tick += 1
      }
    },
  },
}
</script>
